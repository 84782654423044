// import React from "react";
import React,{ useRef } from "react";
import Navbar from "../../Navbar/Navbar";
import ContactForm from "../../Contact_Us/ContactForm";
import Question from "../../Common_Question/Question";
import Footer from "../../Footer/Footer";

import Img1 from "./Testing_1.png";
import Img2 from "./Testing_2.png";
import Img3 from "./Testing_3.png";

const Assessment_Services = () => {

  const contactFormRef = useRef();

  const handleContactClick = () => {
    contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div>
      <Navbar onContactClick={handleContactClick} />

      <style> 
      {`
      @media screen and (max-width: 425px) { 

     .Third_text {
       padding: 0px 3px;
     }

}         `}

      </style>
      <div className="AllMargint" style={{ textAlign: "center" }}>
        <h3 className="heading_test">Welcome to our </h3>
        <h4 className="Colored_text">Vulnerability Assessment Services</h4>
        <p className="Third_text">vulnerability assessment services help you proactively detect </p>
        <p>weaknesses and fortify your defenses</p>
      </div>

      {/* secon two divs */}
      <div className="AllMargint">
        <div className="row About_center">
          <div className="col-md-4 p-3">
            <div>
              {/* <p className="tile_About"> what is </p> */}
              <h4 className="Heading_About">
                Vulnerability Assessment - What's the Scoop :{" "}
              </h4>
              <p className="Anout_paragraph">
                Think of it as a superhero sweep for your digital space. Our
                team searches for any weak points that could let in cyber
                trouble and helps you reinforce them.
              </p>
            </div>
          </div>
          <div className="col-md-4 p-3">
            <div>
              {/* <p className="tile_About">So now</p> */}
              <h4 className="Heading_About">
                Why Opt for Our Vulnerability Assessment :
              </h4>
              <p className="Anout_paragraph">
              💂‍♂️ Digital Bodyguard: We pinpoint potential weak spots in your
                digital armor to make sure your data stays safe. <br />
                🔍 Spot and Fix: Our experts find and fix any hidden weak spots
                before they become a problem.
              </p>
            </div>
          </div>

          <div className="col-md-4 p-3">
            <div>
              {/* <p className="tile_About">So now</p> */}
              <h4 className="Heading_About">What Sets Us Apart :</h4>
              <p className="Anout_paragraph">
                💡 Tailored Strategies: Your digital space is unique, and so are
                our solutions. We customize our approach to fit your specific
                needs. <br />
                🌐 Global Awareness: Stay one step ahead with insights into the
                latest global cyber threats, keeping your defenses up-to-date.
              </p>
            </div>
          </div>
          <div className="col-md-4 p-3">
            <div>
              {/* <p className="tile_About">So now</p> */}
              <h4 className="Heading_About">
                Why Wait? Secure Your Digital Fort Today!
              </h4>
              <p className="Anout_paragraph">
                Count on Secoraa for a robust defense against cyber
                threats. Contact us now for a Vulnerability Assessment that
                keeps your online world safe.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/*verticaly three cards */}
      <div className="AllMargint">
        <div>
          <div className="row About_center">
            <div className="col-md-3 p-4">
              <div className="leftDiv_middle">
                <p className="tile_About">Apart from being a </p>
                <h4 className="Colored_text Colored_text2">
                  Systematic Evaluation
                </h4>
                <p className="Anout_paragraph">
                  t's an essential step in securing your organization against
                  cyber threats
                </p>
              </div>
            </div>
            <div className="col-md-5 p-4">
              <div>
                <div className="card">
                  <div className="card-body custom_card_body">
                    <div className="Custom_card_text">
                      <h5 className="card-title">Risk Reduction</h5>
                      <p className="card-text">
                        Minimize the risk of cyberattacks
                      </p>
                    </div>
                    <img src={Img1} />
                  </div>
                </div>
                <div className="card">
                  <div className="card-body custom_card_body">
                    <div className="Custom_card_text">
                      <h5 className="card-title">Enhanced Security</h5>
                      <p className="card-text">
                        Strengthen your overall security
                      </p>
                    </div>
                    <img src={Img2} />
                  </div>
                </div>
                <div className="card">
                  <div className="card-body custom_card_body">
                    <div className="Custom_card_text">
                      <h5 className="card-title">Compliance</h5>
                      <p className="card-text">
                        Stay aligned with industry regulations
                      </p>
                    </div>

                    <img src={Img3} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Phase */}

      <div className="AllMargint">
        <h4 style={{ textAlign: "center" }}>
          It Includes
          <span className="Colored_text Colored_text"> 4 Stages</span>
        </h4>

        <div>
          <div className="stages_cards">
            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body testing_body">
                    <div className="Testing_card_circle">1</div>
                    <h5 className="card-title testingTitle">Scoping</h5>
                    <p className="card-text testingParagraph">
                      Define the scope and objectives of the assessment
                    </p>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body testing_body">
                    <div className="Testing_card_circle">2</div>
                    <h5 className="card-title testingTitle">Discovery</h5>
                    <p className="card-text testingParagraph">
                      Identify assets and potential <br />
                      vulnerabilities
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body testing_body">
                    <div className="Testing_card_circle">3</div>
                    <h5 className="card-title testingTitle">Assessment</h5>
                    <p className="card-text testingParagraph">
                      Evaluate and prioritize <br />
                      vulnerabilities
                    </p>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body testing_body">
                    <div className="Testing_card_circle">4</div>
                    <h5 className="card-title testingTitle">Reporting</h5>
                    <p className="card-text testingParagraph">
                      Provide detailed reports with <br /> remediation
                      recommendations
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="AllMargint">
        <Question />
      </div>
      <div className="AllMargint">
        <ContactForm ref={contactFormRef}  />
      </div>

      <div className="AllMargint">
        <Footer />
      </div>
    </div>
  );
};

export default Assessment_Services;
