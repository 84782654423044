// import React from "react";
import React, { useRef } from "react";
import "./LandingPage.css";
import Navbar from "../Navbar/Navbar";
import Circle from "../Circle/Circle";
import Img1 from "./Landing1.png";
import Img2 from "./Landing2.png";
import Img3 from "./Landing3.png";
import Img4 from "./Landing4.png";
import Img5 from "./Landing5.png";
import Img6 from "./Landing6.png";
import Prolile from "./pk-aamir (1).jpg";
import ContactForm from "../Contact_Us/ContactForm";
import Footer from "../Footer/Footer";

const LandingPage = () => {
  const contactFormRef = useRef();

  const handleContactClick = () => {
    contactFormRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <Navbar onContactClick={handleContactClick} />
      {/* <Circle onContactClick={handleContactClick} /> */}

      <div id="Landing_circle AllMargint">
        <Circle onContactClick={handleContactClick} />
      </div>

      <div className="LandingPage_two AllMargint">
        <div className="Landing_section_container">
          <div className="tex_in_Landing">
            <p>Unleash a Spectrum of Services for</p>
            <h4 className="Colored_text">Your Every Need!</h4>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 left_side">
                <div className="mt-3 card col-md-7 col-11">
                  <div className="card-body body_of_Landing_section2">
                    <p>Web Application Penetration testing</p>
                    <img src={Img1} alt="" />
                  </div>
                </div>
                <div className="mt-3 card col-md-7 col-11">
                  <div className="card-body body_of_Landing_section2">
                    <p>Vulnerability Assessment</p>
                    <img src={Img2} alt="" />
                  </div>
                </div>
                <div className="mt-3 card col-md-7 col-11">
                  <div className="card-body body_of_Landing_section2">
                    <p>Cloud Security</p>
                    <img src={Img3} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-md-6 right_side">
                <div className="mt-3 card col-md-7 col-11">
                  <div className="card-body body_of_Landing_section2">
                    <p>Managed SOC services</p>
                    <img src={Img4} alt="" />
                  </div>
                </div>
                <div className="mt-3 card col-md-7 col-11">
                  <div className="card-body body_of_Landing_section2">
                    <p>Red Team / Blue Team</p>
                    <img src={Img5} alt="" />
                  </div>
                </div>
                <div className="mt-3 card col-md-7 col-11">
                  <div className="card-body body_of_Landing_section2">
                    <p>Custom Software Development</p>
                    <img src={Img6} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="LandingPage_two AllMargint">
        <div className="Landing_section_container">
          <div className="tex_in_Landing">
            <p>Hear from Our Clients</p>
            <h4 className="Colored_text">Inspiring Success Stories</h4>
          </div>

          <div class="container">
            <div class="row full-row">
              <div class="col-md-4">
                <div class="boxes p-3 mt-4 profile-card">
                  <div class="title_profile">
                    {/* <img class="Profile" src={Prolile} alt="" /> */}
                    <div>
                      <h4 className="names_landing_page">Policyplanner</h4>
                      <p className="position_landing_page">
                        Software Development Partner
                      </p>
                    </div>
                  </div>
                  <div class="col-md-12 p-3">
                    <p className="paragraph_about">
                      We engaged Secoraa It Solutions for our software
                      development needs, and the results have been outstanding.
                      Their team's innovative approach, attention to detail, and
                      commitment to quality have made them a reliable partner.
                    </p>
                  </div>
                </div>
                {/* <div class="boxes p-3 mt-4 profile-card">
                  <div class="title_profile">
                    <img class="Profile" src={Prolile} alt="" />
                    <div>
                      <h4 className="names_landing_page">Vivek Pawar</h4>
                      <p className="position_landing_page">Company Secretary</p>
                    </div>
                  </div>
                  <div class="col-md-12 p-3">
                    <p className="paragraph_about">
                      Vulnerability assessments helped us meet compliance
                      requirements and bolstered our security.
                    </p>
                  </div>
                </div> */}
              </div>
              <div class="col-md-4">
                <div class="boxes p-3 mt-4 profile-card">
                  <div class="title_profile">
                    {/* <img class="Profile" src={Prolile} alt="" /> */}
                    <div>
                      <h4 className="names_landing_page">Easytaxplanner</h4>
                      <p className="position_landing_page">
                        Reliable and Trustworthy
                      </p>
                    </div>
                  </div>
                  <div class="col-md-12 p-3">
                    <p className="paragraph_about">
                      Working with Secoraa It Solutions has been a game-changer
                      for our business. Their cybersecurity expertise is
                      unparalleled, and their commitment to delivering secure
                      solutions is evident in every project.
                    </p>
                  </div>
                </div>
                {/* <div class="boxes p-3 mt-4 profile-card">
                  <div class="title_profile">
                    <img class="Profile" src={Prolile} alt="" />
                    <div>
                      <h4 className="names_landing_page">Diksha Jain</h4>
                      <p className="position_landing_page">CEO</p>
                    </div>
                  </div>
                  <div class="col-md-12 p-3">
                    <p className="paragraph_about">
                      We've partnered with Secoraa for Red Team / Blue Team
                      services, and the results have been outstanding. Their
                      realistic testing and collaborative approach have enhanced
                      our cybersecurity readiness.
                    </p>
                  </div>
                </div> */}
              </div>
              <div class="col-md-4">
                <div class="boxes p-3 mt-4 profile-card">
                  <div class="title_profile">
                    {/* <img class="Profile" src={Prolile} alt="" /> */}
                    <div>
                      <h4 className="names_landing_page">Docsplanner</h4>
                      <p className="position_landing_page">
                        Trustworthy Cybersecurity Experts
                      </p>
                    </div>
                  </div>
                  <div class="col-md-12 p-3">
                    <p className="paragraph_about">
                      When it comes to cybersecurity, trust is paramount, and
                      Secoraa It Solutions has earned ours. Their team has
                      consistently demonstrated a deep understanding of our
                      industry's security needs.
                    </p>
                  </div>
                </div>
                {/* <div class="boxes p-3 mt-4 profile-card">
                  <div class="title_profile">
                    <img class="Profile" src={Prolile} alt="" />
                    <div>
                      <h4 className="names_landing_page">Kavita Kharat</h4>
                      <p className="position_landing_page">Freelancer</p>
                    </div>
                  </div>
                  <div class="col-md-12 p-3">
                    <p className="paragraph_about">
                      Managed SOC services saved us from potential disasters
                      with 24/7 monitoring and rapid response.
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="AllMargint">
          <ContactForm ref={contactFormRef} />
        </div>
        <div className="AllMargint">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
