
import Circle from './Components/Circle/Circle';
import Navbar from './Components/Navbar/Navbar';
import Question from './Components/Common_Question/Question';
import ContactForm from './Components/Contact_Us/ContactForm';
// import ContactEmail from './Components/Contact_Email/ContactEmail';
import LandingPage from './Components/LandingPage/LandingPage';
import About_Us from './Components/About_Us/About_Us';
import Testing_Services from './Components/Our_Services/Testing_Services/Testing_Services';


import { BrowserRouter, Routes, Route, Navigate, HashRouter } from "react-router-dom";

// import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import Assessment_Services from './Components/Our_Services/Assessment_Services/Assessment_Services';
import Security_Services from './Components/Our_Services/Security_Services/Security_Services';
import SOC from './Components/Our_Services/SOC/SOC';
import Team_Services from './Components/Our_Services/Team_Services/Team_Services';
import Development from './Components/Our_Services/Development/Development';




function App() {


  return (
    <HashRouter>
      <Routes>
        <Route path='*' element={<Navigate to='/' />} />
        <Route path="/" element={<LandingPage />} />
        <Route path="/about" element={<About_Us />} />
        <Route path="/Testing" element={<Testing_Services />} />
        <Route path="/Assessment" element={<Assessment_Services />} />
        <Route path="/Security" element={<Security_Services />} />

        <Route path="/SOC" element={<SOC />} />

        <Route path="/Team" element={<Team_Services />} />

        <Route path="/Development" element={<Development />} />
        {/* <Route path="/Contact" element={<ContactEmail />} /> */}

      </Routes>
    </HashRouter>
  );
}

export default App;
