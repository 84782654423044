import React,{ useRef } from "react";
import "./Testing_Services.css";
import Navbar from "../../Navbar/Navbar";

import Img1 from "./Testing_1.png";
import Img2 from "./Testing_2.png";
import Img3 from "./Testing_3.png";
import ContactForm from "../../Contact_Us/ContactForm";
import Question from "../../Common_Question/Question";
import Footer from "../../Footer/Footer";

const Testing_Services = () => {

  const contactFormRef = useRef();

  const handleContactClick = () => {
    contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div>
      <Navbar  onContactClick={handleContactClick} />
      {/* fist text */}
      <div className="AllMargint" style={{ textAlign: "center" }}>
        <h3 className="heading_test">Welcome to our </h3>
        <h4 className="Colored_text">Penetration Testing Services</h4>
        <p>Penetration testing is the key to uncovering vulnerabilities and</p>
        <p>ensuring your systems are fortified against cyber threats</p>
      </div>
      {/* secon two divs */}
      <div className="AllMargint">
        <div className="row About_center">
          <div className="col-md-4 p-4">
            <div>
              {/* <p className="tile_About"> what is </p> */}
              <h4 className="Heading_About">
                Security Testing - What's the Deal :
              </h4>
              <p className="Anout_paragraph">
                Imagine it as a friendly security check—our experts simulate a
                virtual break-in to pinpoint areas where your defenses could use
                a little extra attention. It's all part of ensuring your
                business is shielded against cyber threats.
              </p>
            </div>
          </div>
          <div className="col-md-4 p-4">
            <div>
              {/* <p className="tile_About">So now</p> */}
              <h4 className="Heading_About">Why Opt for Security Testing :</h4>
              <p className="Anout_paragraph">
              👁️‍🗨️ Spot It Before They Do: Our team identifies vulnerabilities
                before cyber adversaries get a chance to exploit them. <br />
                🔧 Fix it Up: Once we uncover a weak spot, we guide you in
                fortifying it so that it's as if it never existed. <br />
                🌐 Stay a Step Ahead: Cyber threats are always evolving. With
                Security Testing, you're consistently in the lead.
              </p>
            </div>
          </div>

          <div className="col-md-4 p-4">
            <div>
              {/* <p className="tile_About">So now</p> */}
              <h4 className="Heading_About">What's in Store :</h4>
              <p className="Anout_paragraph">
                🚀 Enhanced Security: We elevate your defenses, ensuring your
                business is prepared for anything. <br />
                📈 Routine Check-ups: Think of it as a regular health check for
                your digital systems—we keep monitoring to ensure everything
                remains in peak condition.
              </p>
            </div>
          </div>
          <div className="col-md-4 p-4">
            <div>
              {/* <p className="tile_About">So now</p> */}
              <h4 className="Heading_About">
                Ready to Safeguard Your Business ?
              </h4>
              <p className="Anout_paragraph">
                Don't let cyber threats catch you by surprise. Connect with
                Secoraa for Security Testing that transforms
                weaknesses into strengths.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/*verticaly three cards */}
      <div className="AllMargint">
        <div>
          <div className="row About_center">
            <div className="col-md-3 p-4">
              <div className="leftDiv_middle">
                <p className="tile_About">Apart from being a </p>
                <h4 className="Colored_text Colored_text2">
                  Efficient Technique
                </h4>
                <p className="Anout_paragraph">
                  Penetration testing offers numerous benefits for organizations
                  seeking to fortify their cybersecurity defenses
                </p>
              </div>
            </div>
            <div className="col-md-5 p-4">
              <div>
                <div className="card">
                  <div className="card-body custom_card_body">
                    <div className="Custom_card_text">
                      <h5 className="card-title">Prevent Data Breaches</h5>
                      <p className="card-text">
                        Identify and fix vulnerabilities to safeguard data
                      </p>
                    </div>
                    <img src={Img1} />
                  </div>
                </div>
                <div className="card">
                  <div className="card-body custom_card_body">
                    <div className="Custom_card_text">
                      <h5 className="card-title">Mitigate Cyber Threats</h5>
                      <p className="card-text">
                        Stay ahead of evolving threats
                      </p>
                    </div>
                    <img src={Img2} />
                  </div>
                </div>
                <div className="card">
                  <div className="card-body custom_card_body">
                    <div className="Custom_card_text">
                      <h5 className="card-title">Strengthen Security</h5>
                      <p className="card-text">
                        Enhance overall system defenses
                      </p>
                    </div>

                    <img src={Img3} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Phase */}

      <div className="AllMargint">
        <h4 style={{ textAlign: "center" }}>
          It Includes
          <span className="Colored_text Colored_text"> 4 Stages</span>
        </h4>

        <div>
          <div className="stages_cards">
            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body testing_body">
                    <div className="Testing_card_circle">1</div>
                    <h5 className="card-title testingTitle">
                      Planning & Reconnaissance
                    </h5>
                    <p className="card-text testingParagraph">
                      Gathering target system info for attack planning
                    </p>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body testing_body">
                    <div className="Testing_card_circle">2</div>
                    <h5 className="card-title testingTitle">
                      Scanning for Openings
                    </h5>
                    <p className="card-text testingParagraph">
                      Identifying open ports and <br /> weaknesses
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body testing_body">
                    <div className="Testing_card_circle">3</div>
                    <h5 className="card-title testingTitle">Exploitation</h5>
                    <p className="card-text testingParagraph">
                      Attempting to exploit <br /> vulnerabilities
                    </p>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body testing_body">
                    <div className="Testing_card_circle">4</div>
                    <h5 className="card-title testingTitle">
                      Reporting & Improvement
                    </h5>
                    <p className="card-text testingParagraph">
                      Summarizing findings to enhance <br /> security
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="AllMargint">
        <Question />
      </div>
      <div className="AllMargint">
        <ContactForm ref={contactFormRef} />
      </div>

      <div className="AllMargint">
        <Footer />
      </div>
    </div>
  );
};

export default Testing_Services;
