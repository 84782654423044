import React, { useRef } from 'react';
import axios from 'axios';
import './Contact.css';

const ContactForm = React.forwardRef((props, ref) => {
  const form = useRef();

  const sendMail = () => {
    const formData = new FormData(form.current);

    const conditionMessageValue = formData.get('condition_message');
  if (conditionMessageValue) {
    // If condition_message has a value, do something or skip the POST request
    console.log('Condition message has a value:', conditionMessageValue);
    return; // Skip the axios POST request
  }

    // axios.post('https://secoraa.com/Backend/send_email.php', formData,{headers:{
    axios.post('http://localhost/SV_2/Backend/send_email.php', formData,{headers:{
      'Content-Type': 'application/json',
    },})
    .then((response) => {
      console.log(response);
      if (response.data.success === "Message sent successfully!") {
        alert('Thank you! We will contact you shortly.');
        form.current.reset(); // Reset the form
      } else {
        alert(response.data.error);
      }
    })
      .catch((error) => {
        console.error('Error making POST request:', error);
      });
  };

  return (
    <div id='contact' ref={ref}>
      <div className="container-fluid">
        <div className="row form_container">
          <div className="col-md-4">
            <div>
              <p>If the above sounds a great fit for you </p>
              <div className="Question_text_color m-0 p-0">
                Connect with Us Today
              </div>
            </div>
            <form ref={form}>
              <input
                type="text"
                className="Contact_Inpute"
                placeholder="Enter your name"
                name="name"
                required
              />
              <input
                type="email"
                className="Contact_Inpute"
                placeholder="Enter your email"
                name="email"
                required
              />
              <input
                type="text"
                className="Contact_Inpute"
                placeholder="Message ( Optional )"
                name="message"
              />
              <input
                type="text"
                className="Contact_Inpute"
                placeholder="condition_message ( Optional )"
                name="condition_message"
                hidden
              />
              <button
                type="button"
                className="Contact_Inpute contact_send"
                onClick={sendMail}
              >
                Send
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ContactForm;
