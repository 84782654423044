// import React from "react";
import React,{ useRef } from "react";
import "./About_Us.css";
import Navbar from "../Navbar/Navbar";
import ContactForm from "../Contact_Us/ContactForm";
import Footer from "../Footer/Footer";

const About_Us = () => {

  const contactFormRef = useRef();

  const handleContactClick = () => {
    contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div>
      <Navbar onContactClick={handleContactClick} />
      <div className="AllMargint">
        <h6 style={{ textAlign: "center", fontSize: "2rem" }}>
          Let’s talk <span className="Colored_text">About Us</span>
        </h6>
        <div className="row About_center">
          <div className="col-md-4 p-4">
            <div className="boxes">
              {/* <p className="tile_About">Curious about</p> */}
              <h4 className="Heading_About">Who We Are ?</h4>
              <p className="Anout_paragraph">
                At Secoraa, we're a team of cybersecurity enthusiasts with one
                goal – to protect your business from online threats. We believe
                that cybersecurity doesn't have to be complicated; it just has
                to work.
              </p>
            </div>
          </div>
          <div className="col-md-4 p-4">
            <div className="boxes">
              {/* <p className="tile_About">So</p> */}
              <h4 className="Heading_About">Why Choose Us ?</h4>
              <p className="Anout_paragraph">
                👥 Dedicated Team: We've got a team of friendly experts who
                genuinely care about your cybersecurity. <br />  🌐 Tailored Solutions:
                No one-size-fits-all here. Our solutions are personalized to fit
                your unique business needs. <br /> 💡 Continuous Improvement: We're
                always learning and evolving to provide you with the best
                cybersecurity solutions.
              </p>
            </div>
          </div>

          <div className="col-md-4 p-4">
            <div className="boxes">
              {/* <p className="tile_About">So</p> */}
              <h4 className="Heading_About">Our Promise ?</h4>
              <p className="Anout_paragraph">
                🔒 Simplified Security: We make cybersecurity easy to understand
                and implement, so you can focus on what you do best – running
                your business. <br /> 🚀 Proactive Protection: Our team stays
                one step ahead, keeping your digital space safe from the latest
                cyber tricks and threats
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="AllMargint">
        <ContactForm ref={contactFormRef}/>
      </div>
      <div className="AllMargint">
        <Footer />
      </div>
    </div>
  );
};

export default About_Us;
