import React from "react";
import "./Circle.css";
import Circle_1 from "../LOGO/Framecircle_1.png";
import Circle_2 from "../LOGO/Framecircle_2.png";

import Circle_3 from "../LOGO/Framecircle_3.png";

import Circle_4 from "../LOGO/Framecircle_4.png";

import Circle_5 from "../LOGO/Framecircle_5.png";

import Circle_6 from "../LOGO/Framecircle_6.png";

const Circle = ({ onContactClick  }) => {
  return (
    <div>
      <div className="Circle_continer">
        <div className="circle">
          <div className="message">
            <img src={Circle_1} />
          </div>
          <div className="message">
            <img src={Circle_2} />
          </div>
          <div className="message">
            <img src={Circle_3} />
          </div>
          <div className="message">
            <img src={Circle_4} />
          </div>
          <div className="message">
            <img src={Circle_5} />
          </div>
          <div className="message">
            <img src={Circle_6} />
          </div>
        </div>

        <div className="carcle_text_continer">
          <h1>Your Trusted Guardians of</h1>
          <h1 id="Colored_text"> Digital Fortresses</h1>
          <div>
            <p>
              Unlocking Excellence in Cloud Security, Web Application Testing,
              Custom Software Development, and Beyond
            </p>
          </div>
        </div>
      </div>
   
      <div className="Circle_Button_container">
        <div id="button" onClick={onContactClick}>Get in Touch for Demo</div>
      </div>
    </div>
  );
};

export default Circle;
