import React, { useEffect, useState } from 'react';
import './Question.scss';

const Question = () => {

  const [expanded, setExpanded] = useState([]);

  const handleToggle = (index) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });
  };




  const [hash, setHash] = useState('');
  useEffect(() => {
    const handleHashChange = () => {
      const newHash = window.location.hash.slice(1);
      setHash(newHash);
    };
    window.addEventListener('hashchange', handleHashChange);
    const initialHash = window.location.hash.slice(1);
    setHash(initialHash);
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  
  return (
    <div>
      {hash === '/Testing' &&
       <div>




       <div>
      <div className="Question_container">
        <div>
          <div className="card-body">
            <h5 className="card-title">Some of these </h5>
            <p className="text_in_Question">
              <span className="Question_text_color">Questions </span> could be
              in your mind ?
            </p>
          </div>
          <div className="card-body body_borer">
            <div className="Questions_Body" onClick={() => handleToggle(0)}>
              <p className="Questions">
              What is penetration testing, and why is it important for businesses ?
              <span className="Question_Arrow Question_text_color">^</span>
              </p>
       

              {expanded[0] && (
              <div className=" body_borer">
                <div className="Questions_Body">
                  {/* Add your answer input or text area here */}
                 <div><p>Penetration testing, or ethical hacking, is a proactive cybersecurity approach where skilled professionals simulate cyberattacks to identify and address vulnerabilities in a system. </p></div>

                </div>
              </div>
            )}
            </div>
         
          </div>
          <div className="card-body body_borer">
            <div className="Questions_Body" onClick={() => handleToggle(1)}>
              <p className="Questions">Is penetration testing suitable for all types of businesses ?
              <span className="Question_Arrow Question_text_color">^</span>
              </p>
             

              {expanded[1] && (
              <div className="card-body body_borer">
                <div className="Questions_Body">
                  {/* Add your answer input or text area here */}
                  <div><p>Yes, penetration testing is beneficial for businesses of all sizes and industries.</p></div>
                  
                </div>
              </div>
            )}
            </div>
         
          </div>
        </div>
      </div>
    </div>

      </div>}
      {hash === '/Assessment' &&
      
       <div>

       <div>
       <div className="Question_container">
        <div>
          <div className="card-body">
            <h5 className="card-title">Some of these </h5>
            <p className="text_in_Question">
              <span className="Question_text_color">Questions </span> could be
              in your mind ?
            </p>
          </div>
          <div className="card-body body_borer">
            <div className="Questions_Body" onClick={() => handleToggle(0)}>
              <p className="Questions">
              What is the importance of vulnerability assessment for my business ?
              <span className="Question_Arrow Question_text_color">^</span>
              </p>
       

              {expanded[0] && (
              <div className=" body_borer">
                <div className="Questions_Body">
                  {/* Add your answer input or text area here */}
                 <div><p>By regularly assessing vulnerabilities, you can proactively enhance your security posture, reduce the risk of cyberattacks, and protect sensitive data from unauthorized access.</p></div>

                </div>
              </div>
            )}
            </div>
         
          </div>
          <div className="card-body body_borer">
            <div className="Questions_Body" onClick={() => handleToggle(1)}>
              <p className="Questions">Is vulnerability assessment suitable for businesses of all sizes ?
              <span className="Question_Arrow Question_text_color">^</span>
              </p>
             

              {expanded[1] && (
              <div className="card-body body_borer">
                <div className="Questions_Body">
                  {/* Add your answer input or text area here */}
                  <div><p>Yes, vulnerability assessments are essential for businesses of all sizes. Small and medium-sized enterprises, as well as large enterprises, can benefit from regular assessments to ensure the security of their digital assets and sensitive information.</p></div>
                  
                </div>
              </div>
            )}
            </div>
         
          </div>
        </div>
      </div>
    </div>

       </div>
       }
      {hash === '/Security' &&
       <div>
       <div className="Question_container">
        <div>
          <div className="card-body">
            <h5 className="card-title">Some of these </h5>
            <p className="text_in_Question">
              <span className="Question_text_color">Questions </span> could be
              in your mind ?
            </p>
          </div>
          <div className="card-body body_borer">
            <div className="Questions_Body" onClick={() => handleToggle(0)}>
              <p className="Questions">
              What is Cloud Computing ?
              <span className="Question_Arrow Question_text_color">^</span>
              </p>
       

              {expanded[0] && (
              <div className=" body_borer">
                <div className="Questions_Body">
                  {/* Add your answer input or text area here */}
                 <div><p>Cloud computing is a technology that enables access to computing resources (such as servers, storage, databases, networking, software, and analytics) over the internet.</p></div>

                </div>
              </div>
            )}
            </div>
         
          </div>
          <div className="card-body body_borer">
            <div className="Questions_Body" onClick={() => handleToggle(1)}>
              <p className="Questions">How does the shared responsibility model work in cloud security ?
              <span className="Question_Arrow Question_text_color">^</span>
              </p>
             

              {expanded[1] && (
              <div className="card-body body_borer">
                <div className="Questions_Body">
                  {/* Add your answer input or text area here */}
                  <div><p>The shared responsibility model defines the division of security responsibilities between cloud providers and users. While providers secure the infrastructure, users are responsible for securing their data, applications, and access credentials.</p></div>
                  
                </div>
              </div>
            )}
            </div>
         
          </div>
        </div>
      </div>
       </div>
       }
       {hash === '/SOC' &&
       <div>
     
       <div>
       <div className="Question_container">
        <div>
          <div className="card-body">
            <h5 className="card-title">Some of these </h5>
            <p className="text_in_Question">
              <span className="Question_text_color">Questions </span> could be
              in your mind ?
            </p>
          </div>
          <div className="card-body body_borer">
            <div className="Questions_Body" onClick={() => handleToggle(0)}>
              <p className="Questions">
              What is a Security Operations Center (SOC)?
              <span className="Question_Arrow Question_text_color">^</span>
              </p>
       

              {expanded[0] && (
              <div className=" body_borer">
                <div className="Questions_Body">
                  {/* Add your answer input or text area here */}
                 <div><p>A Security Operations Center (SOC) is a centralized hub within an organization dedicated to monitoring, detecting, and responding to cybersecurity threats. </p></div>

                </div>
              </div>
            )}
            </div>
         
          </div>
          <div className="card-body body_borer">
            <div className="Questions_Body" onClick={() => handleToggle(1)}>
              <p className="Questions">Why is a SOC important for businesses ?
              <span className="Question_Arrow Question_text_color">^</span>
              </p>
             

              {expanded[1] && (
              <div className="card-body body_borer">
                <div className="Questions_Body">
                  {/* Add your answer input or text area here */}
                  <div><p>A SOC is vital for businesses as it provides real-time monitoring of security events, rapid incident response, and continuous improvement of security measures. </p></div>
                  
                </div>
              </div>
            )}
            </div>
         
          </div>
        </div>
      </div>
    </div>
       </div>
       }
       {hash === '/Team' &&
       <div>
       <div className="Question_container">
        <div>
          <div className="card-body">
            <h5 className="card-title">Some of these </h5>
            <p className="text_in_Question">
              <span className="Question_text_color">Questions </span> could be
              in your mind ?
            </p>
          </div>
          <div className="card-body body_borer">
            <div className="Questions_Body" onClick={() => handleToggle(0)}>
              <p className="Questions">
              What is Red Teaming ?
              <span className="Question_Arrow Question_text_color">^</span>
              </p>
       

              {expanded[0] && (
              <div className=" body_borer">
                <div className="Questions_Body">
                  {/* Add your answer input or text area here */}
                 <div><p>Red Teaming is a cybersecurity practice that involves simulating real-world cyber-attacks on a system, network, or organization. It helps identify vulnerabilities and weaknesses in security defenses.</p></div>

                </div>
              </div>
            )}
            </div>
         
          </div>
          <div className="card-body body_borer">
            <div className="Questions_Body" onClick={() => handleToggle(1)}>
              <p className="Questions">. What is Blue Teaming ?
              <span className="Question_Arrow Question_text_color">^</span>
              </p>
             

              {expanded[1] && (
              <div className="card-body body_borer">
                <div className="Questions_Body">
                  {/* Add your answer input or text area here */}
                  <div><p>Blue Teaming involves the defensive side of cybersecurity, focusing on implementing security measures and responding to incidents. </p></div>
                  
                </div>
              </div>
            )}
            </div>
         
          </div>
        </div>
      </div>
       </div>
       }
       {hash === '/Development' &&
       <div>
       <div className="Question_container">
        <div>
          <div className="card-body">
            <h5 className="card-title">Some of these </h5>
            <p className="text_in_Question">
              <span className="Question_text_color">Questions </span> could be
              in your mind ?
            </p>
          </div>
          <div className="card-body body_borer">
            <div className="Questions_Body" onClick={() => handleToggle(0)}>
              <p className="Questions">
              Why is professional software development important for my business ?
              <span className="Question_Arrow Question_text_color">^</span>
              </p>
       

              {expanded[0] && (
              <div className=" body_borer">
                <div className="Questions_Body">
                  {/* Add your answer input or text area here */}
                 <div><p>Professional software development is crucial for businesses as it ensures tailored solutions that meet specific needs, enhances efficiency, automates processes, and contributes to overall business growth.</p></div>

                </div>
              </div>
            )}
            </div>
         
          </div>
          <div className="card-body body_borer">
            <div className="Questions_Body" onClick={() => handleToggle(1)}>
              <p className="Questions">How do I determine the right software solution for my business ?
              <span className="Question_Arrow Question_text_color">^</span>
              </p>
             

              {expanded[1] && (
              <div className="card-body body_borer">
                <div className="Questions_Body">
                  {/* Add your answer input or text area here */}
                  <div><p>The right software solution depends on your business requirements. A thorough analysis of your needs, consultation with experts, and consideration of scalability and future growth are essential for making an informed decision.</p></div>
                  
                </div>
              </div>
            )}
            </div>
         
          </div>
        </div>
      </div>
       </div>
       }
    </div>
  );
};

export default Question;



// import React, { useState } from 'react';
// import './Question.scss';

// const Question = () => {
  // const [expanded, setExpanded] = useState([]);

  // const handleToggle = (index) => {
  //   setExpanded((prevExpanded) => {
  //     const newExpanded = [...prevExpanded];
  //     newExpanded[index] = !newExpanded[index];
  //     return newExpanded;
  //   });
  // };

//   return (
    // <div>
    //   <div className="Question_container">
    //     <div>
    //       <div className="card-body">
    //         <h5 className="card-title">Some of these </h5>
    //         <p className="text_in_Question">
    //           <span className="Question_text_color">Questions </span> could be
    //           in your mind ?
    //         </p>
    //       </div>
    //       <div className="card-body body_borer">
    //         <div className="Questions_Body" onClick={() => handleToggle(0)}>
    //           <p className="Questions">
    //           What is Cloud Computing ?
    //           </p>
    //           <span className="Question_Arrow Question_text_color">^</span>
    //         </div>
    //         {expanded[0] && (
    //           <div className="card-body body_borer">
    //             <div className="Questions_Body">
    //               {/* Add your answer input or text area here */}
    //              <div><p>Cloud computing is a technology that enables access to computing resources (such as servers, storage, databases, networking, software, and analytics) over the internet. </p></div>
    //             </div>
    //           </div>
    //         )}
    //       </div>
    //       <div className="card-body body_borer">
    //         <div className="Questions_Body" onClick={() => handleToggle(1)}>
    //           <p className="Questions">How does the shared responsibility model work in cloud security?</p>
    //           <span className="Question_Arrow Question_text_color">^</span>
    //         </div>
    //         {expanded[1] && (
    //           <div className="card-body body_borer">
    //             <div className="Questions_Body">
    //               {/* Add your answer input or text area here */}
    //               <div><p>The shared responsibility model defines the division of security responsibilities between cloud providers and users. </p></div>
                  
    //             </div>
    //           </div>
    //         )}
    //       </div>
    //     </div>
    //   </div>
    // </div>
//   );
// };

// export default Question;
