import React,{ useRef } from "react";
import "./SOC.css";
import Navbar from "../../Navbar/Navbar";
import ContactForm from "../../Contact_Us/ContactForm";
import Question from "../../Common_Question/Question";
import Footer from "../../Footer/Footer";

import Img1 from "./Testing_1.png";
import Img2 from "./Testing_2.png";
import Img3 from "./Testing_3.png";
const SOC = () => {
  const contactFormRef = useRef();

  const handleContactClick = () => {
    contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div>
      <Navbar onContactClick={handleContactClick} />
      <div className="AllMargint" style={{ textAlign: "center" }}>
        <h3 className="heading_test">Welcome to our </h3>
        <h4 className="Colored_text">Managed SOC services</h4>
        <p className="Third_text">
          Our SOC services provide round-the-clock security to protect your
          <p> digital assets</p>
        </p>
      </div>

      {/* secon two divs */}
      <div className="AllMargint">
        <div className="row About_center">
          <div className="col-md-4 p-4">
            <div>
              {/* <p className="tile_About"> what is </p> */}
              <h4 className="Heading_About">Why Opt for Our SOC Services ?</h4>
              <p className="Anout_paragraph">
                🔒 24/7 Cybersecurity Monitoring: Our SOC experts provide
                constant, real-time monitoring to ensure your business is secure
                around the clock. <br /> 🌐 Global Threat Intelligence
                Integration: Stay ahead of cyber threats with our cutting-edge
                global threat intelligence integration, fortifying your defenses
                against emerging risks. <br /> 🛡 Proactive Cyber Defense: Our
                SOC services go beyond reactive measures, actively identifying
                and neutralizing potential risks before they pose a threat.{" "}
                <br /> 🔧 Tailored Cybersecurity Solutions: Benefit from
                customized cybersecurity solutions designed to fit the unique
                security needs of your organization.
              </p>
            </div>
          </div>
          <div className="col-md-4 p-4">
            <div>
              {/* <p className="tile_About">So now</p> */}
              <h4 className="Heading_About">Our Offerings ?</h4>
              <p className="Anout_paragraph">
                🚨 Rapid Incident Response: Swift and effective response to
                security incidents, minimizing potential damage to your
                business. <br />
                🌐 Continuous Threat Monitoring: Real-time surveillance to
                detect and thwart cyber threats before they impact your
                operations. <br />
                📈 Performance Optimization: Fine-tune your security measures
                based on our expert analysis, ensuring optimal protection.
              </p>
            </div>
          </div>

          <div className="col-md-4 p-4">
            <div>
              {/* <p className="tile_About">So now</p> */}
              <h4 className="Heading_About">
                Why SOC is Crucial for Your Business :{" "}
              </h4>
              <p className="Anout_paragraph">
                In today's digital landscape, cybersecurity is non-negotiable. A
                dedicated SOC is your frontline defense against evolving cyber
                threats, safeguarding your data, operations, and reputation.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/*verticaly three cards */}
      <div className="AllMargint">
        <div>
          <div className="row About_center">
            <div className="col-md-3 p-4">
              <div className="leftDiv_middle">
                <p className="tile_About">Our SOC services provide </p>
                <h4 className="Colored_text Colored_text2">Round-the-clock</h4>
                <p className="Anout_paragraph">
                  security to protect your digital assets
                </p>
              </div>
            </div>
            <div className="col-md-5 p-4">
              <div>
                <div className="card">
                  <div className="card-body custom_card_body">
                    <div className="Custom_card_text">
                      <h5 className="card-title">24/7 Monitoring</h5>
                      <p className="card-text">
                        Continuous threat surveillance
                      </p>
                    </div>
                    <img src={Img1} />
                  </div>
                </div>
                <div className="card">
                  <div className="card-body custom_card_body">
                    <div className="Custom_card_text">
                      <h5 className="card-title">Swift Response</h5>
                      <p className="card-text">Immediate threat containment</p>
                    </div>
                    <img src={Img2} />
                  </div>
                </div>
                <div className="card">
                  <div className="card-body custom_card_body">
                    <div className="Custom_card_text">
                      <h5 className="card-title">Worry-Free Security</h5>
                      <p className="card-text">
                        Focus on business, we protect you.
                      </p>
                    </div>

                    <img src={Img3} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Phase */}

      <div className="AllMargint">
        <h4 style={{ textAlign: "center" }}>
          It Includes
          <span className="Colored_text Colored_text"> 4 Stages</span>
        </h4>

        <div>
          <div className="stages_cards">
            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body testing_body">
                    <div className="Testing_card_circle">1</div>
                    <h5 className="card-title testingTitle">Monitoring</h5>
                    <p className="card-text testingParagraph">
                      Continuous surveillance of network and system activities
                    </p>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body testing_body">
                    <div className="Testing_card_circle">2</div>
                    <h5 className="card-title testingTitle">
                      Incident Identification
                    </h5>
                    <p className="card-text testingParagraph">
                      Prompt detection and <br /> categorization of security
                      incidents
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body testing_body">
                    <div className="Testing_card_circle">3</div>
                    <h5 className="card-title testingTitle">
                      Incident Response
                    </h5>
                    <p className="card-text testingParagraph">
                      Immediate action to contain and <br /> mitigate threats
                    </p>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body testing_body">
                    <div className="Testing_card_circle">4</div>
                    <h5 className="card-title testingTitle">
                      Reporting and Improvement
                    </h5>
                    <p className="card-text testingParagraph">
                      Detailed insights and recommendations <br /> for security
                      enhancement
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="AllMargint">
        <Question />
      </div>
      <div className="AllMargint">
        <ContactForm ref={contactFormRef}/>
      </div>

      <div className="AllMargint">
        <Footer />
      </div>
    </div>
  );
};

export default SOC;
