// import React from "react";
import React,{ useRef } from "react";
import Navbar from "../../Navbar/Navbar";
import ContactForm from "../../Contact_Us/ContactForm";
import Question from "../../Common_Question/Question";
import Footer from "../../Footer/Footer";

import Img1 from "./Testing_1.png";
import Img2 from "./Testing_2.png";
import Img3 from "./Testing_3.png";
import "./Development.css";
const Development = () => {

  const contactFormRef = useRef();

  const handleContactClick = () => {
    contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
  };


  return (
    <div>
      <Navbar onContactClick={handleContactClick}/>
      <div style={{ textAlign: "center" }} className="AllMargint">
        <h3 className="heading_test">Welcome to our </h3>
        <h4 className="Colored_text">Custom Software Development Services</h4>
        <p>
          Our services deliver bespoke software that meets your unique needs
        </p>
      </div>
      {/* secon two divs */}
      <div className="AllMargint">
        <div className="row About_center">
          <div className="col-md-4 p-4">
            <div>
              {/* <p className="tile_About">Bu what is </p> */}
              <h4 className="Heading_About">
                Software Development - What's the Buzz:
              </h4>
              <p className="Anout_paragraph">
                It's like building a custom home but in the digital space. Our
                team creates tailored software that fits your unique needs—no
                cookie-cutter solutions here!
              </p>
            </div>
          </div>
          <div className="col-md-4 p-4">
            <div>
              {/* <p className="tile_About">So now</p> */}
              <h4 className="Heading_About">
                Why Choose Our Software Development :{" "}
              </h4>
              <p className="Anout_paragraph">
                🛠 Bespoke Solutions: We design software just for you, like a
                custom-made suit tailored to your exact measurements. <br /> 🌐
                Future-Ready Technology: Stay ahead of the curve with software
                that's built to adapt and grow as your needs evolve. <br /> 🚀
                Smooth User Experience: We focus on making sure your software is
                easy to use—because simplicity is key!
              </p>
            </div>
          </div>
          <div className="col-md-4 p-4">
            <div>
              {/* <p className="tile_About">So now</p> */}
              <h4 className="Heading_About">What You Get : </h4>
              <p className="Anout_paragraph">
                🎉 Digital Wow Factor: Our software doesn't just work; it wows!
                Expect a seamless and impressive digital experience. <br />
                🌐 Reliable Support: Count on us for ongoing assistance and
                troubleshooting. We're here to ensure your software runs
                smoothly. <br />
                🚀 Innovative Features: Stay on the cutting edge with software
                that evolves, introducing fresh and innovative features over
                time.
              </p>
            </div>
          </div>
          <div className="col-md-4 p-4">
            <div>
              {/* <p className="tile_About">So now</p> */}
              <h4 className="Heading_About">Ready to Go Digital ? </h4>
              <p className="Anout_paragraph">
                Let Secoraa be your digital architect. Contact us today to turn
                your software dreams into a virtual reality.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/*verticaly three cards */}
      <div className="AllMargint">
        <div>
          <div className="row About_center">
            <div className="col-md-3 p-4">
              <div className="leftDiv_middle">
                <p className="tile_About">Our track record of </p>
                <h4 className="Colored_text Colored_text2">
                  and satisfied clients speaks for itself
                </h4>
              </div>
            </div>
            <div className="col-md-5 p-4">
              <div>
                <div className="card">
                  <div className="card-body custom_card_body">
                    <div className="Custom_card_text">
                      <h5 className="card-title">Tailored Solutions</h5>
                      <p className="card-text">
                        Software designed just for you
                      </p>
                    </div>
                    <img src={Img1} />
                  </div>
                </div>
                <div className="card">
                  <div className="card-body custom_card_body">
                    <div className="Custom_card_text">
                      <h5 className="card-title">Efficiency Boost</h5>
                      <p className="card-text">
                        Streamlined processes and productivity
                      </p>
                    </div>
                    <img src={Img2} />
                  </div>
                </div>
                <div className="card">
                  <div className="card-body custom_card_body">
                    <div className="Custom_card_text">
                      <h5 className="card-title">Scalability</h5>
                      <p className="card-text">Software that grows as you do</p>
                    </div>

                    <img src={Img3} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Phase */}

      <div className="AllMargint">
        <h4 style={{ textAlign: "center" }}>
          It Includes
          <span className="Colored_text Colored_text"> 4 Stages</span>
        </h4>

        <div>
          <div className="stages_cards">
            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body testing_body">
                    <div className="Testing_card_circle">1</div>
                    <h5 className="card-title testingTitle">
                      Planning and Requirements
                    </h5>
                    <p className="card-text testingParagraph">
                      Defining project scope and <br /> gathering requirements
                    </p>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body testing_body">
                    <div className="Testing_card_circle">2</div>
                    <h5 className="card-title testingTitle">
                      Design and Development
                    </h5>
                    <p className="card-text testingParagraph">
                      Creating a custom solution tailored <br /> to your needs
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body testing_body">
                    <div className="Testing_card_circle">3</div>
                    <h5 className="card-title testingTitle">
                      Testing and Quality Assurance
                    </h5>
                    <p className="card-text testingParagraph">
                      Rigorous testing to ensure <br /> reliability
                    </p>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body testing_body">
                    <div className="Testing_card_circle">4</div>
                    <h5 className="card-title testingTitle">
                      Deployment and Support
                    </h5>
                    <p className="card-text testingParagraph">
                      Smooth integration and ongoing <br />
                      support
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="AllMargint">
        <Question />
      </div>
      <div className="AllMargint">
        <ContactForm ref={contactFormRef}/>
      </div>

      <div className="AllMargint">
        <Footer />
      </div>
    </div>
  );
};

export default Development;
