import React from "react";
import "./Footer.css";

import Secora from "./secora.png";
import EmailImage from "./Email.png";
import Email from "./email_crop_img.png";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-3 Logo">
            <div>
              <img
                src={Secora}
                alt=""
                style={{ width: "150px" }}
                className="mb-2"
              />
            </div>
            <div id="Email">
              <img src={EmailImage} alt="" />
              <img src={Email} alt="" />
    
            </div>
          </div>
          <div className="col-md-3">
            <h6 className="Footer_title">Our Services</h6>
            <ul className="Footer_List">
          <li> <Link to="/Testing">Web Application Penetration testing</Link></li> 
          <li> <Link to="/Assessment">Vulnerability Assessment</Link></li> 
           <li> <Link to="/Security">Cloud Security</Link></li>
          <li>  <Link to="/SOC">Managed SOC services</Link></li>
           <li> <Link to="/Team">Red Team / Blue Team</Link></li>
          <li> <Link to="/Development">Custom Software Development</Link></li> 
              {/* <li>Plaform As a Services</li> */}
            </ul>
          </div>
          <div className="col-md-3 about-link">
            <h6 className="Footer_title">Quick Links</h6>
            <ul className="Footer_List">
           <li><Link to="/about">About Us</Link></li> 
              <li>Blogs</li>
              {/* <li>Contact Us</li> */}
            </ul>
          </div>
          <div className="col-md-3">
            <div>
              <h6 className="Footer_title">India Office</h6>
              <p className="footer_desc">
                B3 KPCT Mall, Vishal Mega Mart, Fatima Nagar Wanawadi Pune
                411013
              </p>
            </div>
            <div>
              <h6 className="Footer_title">Sweden Office</h6>
              <p className="footer_desc">
                Folkungagatan 69, 11622, Stockholm Sweden.
              </p>
            </div>
          
            <div className="caption">
              <h6> <span style={{color: "#1858b5", fontWeight: 600 }}> SECORAA </span> <b  className="footer_desc">IT SOLUTIONS</b>  <span style={{color: "#0dcaf0",fontWeight: 600 }}>PRIVATE LIMITED</span> </h6>
              <p className="Footer_title">CIN No.U80200PN2023PTC225578</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
