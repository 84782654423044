// import React from "react";
import React,{ useRef } from "react";
import "./Security_Services.css";
import Navbar from "../../Navbar/Navbar";
import ContactForm from "../../Contact_Us/ContactForm";
import Question from "../../Common_Question/Question";
import Footer from "../../Footer/Footer";

import Img1 from "./Testing_1.png";
import Img2 from "./Testing_2.png";
import Img3 from "./Testing_3.png";

const Security_Services = () => {

  const contactFormRef = useRef();

  const handleContactClick = () => {
    contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div>
      <Navbar onContactClick={handleContactClick}/>
      <div className="AllMargint" style={{ textAlign: "center" }}>
        <h3 className="heading_test">Welcome to our </h3>
        <h4 className="Colored_text">Cloud Security Services</h4>
        <p>
          cloud security services provide comprehensive protection and peace of
        </p>
        <p> mind to your Cloud Infrastructure</p>
      </div>

      {/* secon two divs */}
      <div className="AllMargint">
        <div className="row About_center">
          <div className="col-md-4 p-4">
            <div>
              {/* <p className="tile_About"> what is </p> */}
              <h4 className="Heading_About">Cloud Security Made Simple : </h4>
              <p className="Anout_paragraph">
                At Secoraa, we believe in making cloud security simple for
                everyone. Whether you're a small business or a growing
                enterprise, our solutions are designed to keep your data safe
                without the tech jargon.
              </p>
            </div>
          </div>
          <div className="col-md-4 p-4">
            <div>
              {/* <p className="tile_About">So now</p> */}
              <h4 className="Heading_About">Why Choose Our Cloud Security :</h4>
              <p className="Anout_paragraph">
                🌐 Global Protection: Our cloud security covers you everywhere –
                your data is safe and sound no matter where you are. <br /> 🔒
                Fortified Defenses: We build strong walls around your data, so
                you can have peace of mind knowing your information is secure.{" "}
                <br />
                🚀 Seamless Integration: No headaches here. Our solutions
                seamlessly fit into your existing systems, making security a
                breeze.
              </p>
            </div>
          </div>

          <div className="col-md-4 p-4">
            <div>
              {/* <p className="tile_About">So now</p> */}
              <h4 className="Heading_About">What We Offer :</h4>
              <p className="Anout_paragraph">
                📈 Scalable Security: Grow your business without worries. Our
                cloud security grows with you. <br /> 👥 Expert Support: Our
                friendly team is here to help you, 24/7. Got a question? Just
                ask!
              </p>
            </div>
          </div>
          <div className="col-md-4 p-4">
            <div>
              {/* <p className="tile_About">So now</p> */}
              <h4 className="Heading_About">Ready to Secure Your Cloud ?</h4>
              <p className="Anout_paragraph">
                Join the Secoraa family and experience worry-free cloud
                security. Contact us today for a consultation tailored to your
                needs.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/*verticaly three cards */}
      <div className="AllMargint">
        <div>
          <div className="row About_center">
            <div className="col-md-3 p-4">
              <div className="leftDiv_middle">
                <p className="tile_About">It’s essential for </p>
                <h4 className="Colored_text Colored_text2">safeguarding</h4>
                <p className="Anout_paragraph">
                  your digital assets, data and applications in Cloud
                </p>
              </div>
            </div>
            <div className="col-md-5 p-4">
              <div>
                <div className="card">
                  <div className="card-body custom_card_body">
                    <div className="Custom_card_text">
                      <h5 className="card-title">Data Security</h5>
                      <p className="card-text">Safeguard cloud-stored data</p>
                    </div>
                    <img src={Img1} />
                  </div>
                </div>
                <div className="card">
                  <div className="card-body custom_card_body">
                    <div className="Custom_card_text">
                      <h5 className="card-title">Real-time Monitoring</h5>
                      <p className="card-text">Detect and respond to threats</p>
                    </div>
                    <img src={Img2} />
                  </div>
                </div>
                <div className="card">
                  <div className="card-body custom_card_body">
                    <div className="Custom_card_text">
                      <h5 className="card-title">Compliance</h5>
                      <p className="card-text">
                        Meet industry standards effortlessly
                      </p>
                    </div>

                    <img src={Img3} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Phase */}

      <div className="AllMargint">
        <h4 style={{ textAlign: "center" }}>
          It Includes
          <span className="Colored_text Colored_text"> 4 Stages</span>
        </h4>

        <div>
          <div className="stages_cards">
            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body testing_body">
                    <div className="Testing_card_circle">1</div>
                    <h5 className="card-title testingTitle">Assessment</h5>
                    <p className="card-text testingParagraph">
                      Evaluate your cloud infrastructure for vulnerabilities and
                      risks
                    </p>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body testing_body">
                    <div className="Testing_card_circle">2</div>
                    <h5 className="card-title testingTitle">Implementation</h5>
                    <p className="card-text testingParagraph">
                      Deploy robust security measures <br /> and access controls
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body testing_body">
                    <div className="Testing_card_circle">3</div>
                    <h5 className="card-title testingTitle">Monitoring</h5>
                    <p className="card-text testingParagraph">
                      Continuously monitor for threats <br /> and
                      vulnerabilities
                    </p>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body testing_body">
                    <div className="Testing_card_circle">4</div>
                    <h5 className="card-title testingTitle">Response</h5>
                    <p className="card-text testingParagraph">
                      Act swiftly in the event of security <br /> incidents
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="AllMargint">
        <Question />
      </div>
      <div className="AllMargint">
        <ContactForm ref={contactFormRef}/>
      </div>

      <div className="AllMargint">
        <Footer />
      </div>
    </div>
  );
};

export default Security_Services;
