import "./Team_Services.css";
import Navbar from "../../Navbar/Navbar";
import ContactForm from "../../Contact_Us/ContactForm";
import Question from "../../Common_Question/Question";
import Footer from "../../Footer/Footer";

import Img1 from "./Testing_1.png";
import Img2 from "./Testing_2.png";
import Img3 from "./Testing_3.png";
import React,{ useRef } from "react";

const Team_Services = () => {

  const contactFormRef = useRef();

  const handleContactClick = () => {
    contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <div>
      <Navbar onContactClick={handleContactClick}/>
      <div className="AllMargint" style={{ textAlign: "center" }}>
        <h3 className="heading_test">Welcome to our </h3>
        <h4 className="Colored_text">Red Team / Blue Team Services</h4>
        <p>Our services offer comprehensive testing and security enhancement</p>
      </div>

      {/* secon two divs */}
      <div className="AllMargint">
        <div className="row About_center">
          <div className="col-md-4 p-4">
            <div>
              {/* <p className="tile_About"> what is </p> */}
              <h4 className="Heading_About">Why Choose Our Red Team ?</h4>
              <p className="Anout_paragraph">
                Real-Life Testing: We simulate cyber-attacks to find and fix any
                vulnerabilities before the bad guys can find them. Proactive{" "}
                <br />
                Solutions: Our Red Team helps you stay one step ahead, making
                your defenses super strong.
              </p>
            </div>
          </div>
          <div className="col-md-4 p-4">
            <div>
              {/* <p className="tile_About">So now</p> */}
              <h4 className="Heading_About">Why Choose Our Blue Team ?</h4>
              <p className="Anout_paragraph">
                Constant Watch: Our Blue Team keeps an eye on your digital space
                all the time, making sure nothing sneaky gets through. <br />  Fast
                Response: If there's ever a problem, our Blue Team jumps into
                action, fixing things up super fast.  
              </p>
            </div>
          </div>
        </div>
      </div>

      {/*verticaly three cards */}
      <div className="AllMargint">
        <div>
          <div className="row About_center">
            <div className="col-md-3 p-4">
              <div className="leftDiv_middle">
                <p className="tile_About">Our experts work together to </p>
                <h4 className="Colored_text Colored_text2">Test and Fortify</h4>
                <p className="Anout_paragraph">your cybersecurity posture</p>
              </div>
            </div>
            <div className="col-md-5 p-4">
              <div>
                <div className="card">
                  <div className="card-body custom_card_body">
                    <div className="Custom_card_text">
                      <h5 className="card-title">Real-World Testing</h5>
                      <p className="card-text">
                        Simulate actual attacks for vulnerability discovery
                      </p>
                    </div>
                    <img src={Img1} />
                  </div>
                </div>
                <div className="card">
                  <div className="card-body custom_card_body">
                    <div className="Custom_card_text">
                      <h5 className="card-title">Full-Spectrum Defense</h5>
                      <p className="card-text">
                        Comprehensive testing and proactive security
                      </p>
                    </div>
                    <img src={Img2} />
                  </div>
                </div>
                <div className="card">
                  <div className="card-body custom_card_body">
                    <div className="Custom_card_text">
                      <h5 className="card-title">Improved Readiness</h5>
                      <p className="card-text">
                        Enhanced incident response and preparedness
                      </p>
                    </div>

                    <img src={Img3} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Phase */}

      <div className="AllMargint">
        <h4 style={{ textAlign: "center" }}>
          <span className="Colored_text Colored_text">Red Team Phases</span>
        </h4>

        <div>
          <div className="stages_cards ">
            <div className="row Team_Services">
              <div className="team_service_card">
                <div className="card">
                  <div className="card-body testing_body">
                    <div className="Testing_card_circle">1</div>
                    <h5 className="card-title testingTitle">Reconnaissance</h5>
                    <p className="card-text testingParagraph">
                      Gathering information for realistic <br /> attack
                      scenarios
                    </p>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body testing_body">
                    <div className="Testing_card_circle">2</div>
                    <h5 className="card-title testingTitle">
                      Attack Simulation
                    </h5>
                    <p className="card-text testingParagraph">
                      Testing vulnerabilities through <br /> controlled attacks.
                    </p>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body testing_body">
                    <div className="Testing_card_circle">3</div>
                    <h5 className="card-title testingTitle">Reporting</h5>
                    <p className="card-text testingParagraph">
                      Detailed findings and <br /> recommendations for
                      improvement
                    </p>
                  </div>
                </div>
              </div>
              <h4 style={{ textAlign: "center" }}>
                <span className="Colored_text Colored_text">
                  Blue Team Phases
                </span>
              </h4>
              <div className="team_service_card">
                <div className="card">
                  <div className="card-body testing_body">
                    <div className="Testing_card_circle">1</div>
                    <h5 className="card-title testingTitle">Monitoring</h5>
                    <p className="card-text testingParagraph">
                      Continuous surveillance of network <br /> and system
                      activities
                    </p>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body testing_body">
                    <div className="Testing_card_circle">2</div>
                    <h5 className="card-title testingTitle">
                      Incident Identification
                    </h5>
                    <p className="card-text testingParagraph">
                      Swift detection and categorization <br /> of security
                      incidents
                    </p>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body testing_body">
                    <div className="Testing_card_circle">3</div>
                    <h5 className="card-title testingTitle">
                      Response and Mitigation
                    </h5>
                    <p className="card-text testingParagraph">
                      Immediate action to contain and <br /> resolve threats
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="AllMargint">
        <Question />
      </div>
      <div className="AllMargint">
        <ContactForm ref={contactFormRef} />
      </div>

      <div className="AllMargint">
        <Footer />
      </div>
    </div>
  );
};

export default Team_Services;
