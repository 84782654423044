import React, { useState } from "react";
import "./Navbar.scss";
import Logo from "../LOGO/Secoraa Logo 1.png";
import { Link } from "react-router-dom";

function Navbar({ onContactClick }) {

  const handleLinkClick = () => {
    // Navigate to the relative path assuming your development server is configured to serve both src and backend folders
    window.location.href = 'https://secoraa.com/Backend/web_scanner.php';
    // window.location.href = 'https://localhost/SV_2/Backend/web_scanner.php';
    console.log('Clicked');
  };


  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isServicesDropdownOpen, setServicesDropdownOpen] = useState(false);

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleServicesDropdownToggle = () => {
    setServicesDropdownOpen(!isServicesDropdownOpen);
  };

  return (
    <div>
      <nav className="navbar">
        <div className="container">
          <div className="navbar-header">
            <button
              className="navbar-toggler"
              data-toggle="open-navbar1"
              onClick={handleMobileMenuToggle}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>

            <Link to="/">
              {" "}
              <img src={Logo} alt="Logo" />
            </Link>
          </div>

          <div
            className={`navbar-menu${isMobileMenuOpen ? " active" : ""}`}
            id="open-navbar1"
          >
            <ul className="navbar-nav">
              <li className="active">
                <Link to="/">Home</Link>
              </li>
              <li className="active">
                <Link to="/about">About Us</Link>
              </li>
              <li
                className="our_service"
                onClick={handleServicesDropdownToggle}
              >
                <a>Our Services</a>
                {isServicesDropdownOpen && (
                  <div
                    className="navbar-dropdown show"
                    data-dropdown="services-dropdown"
                  >
                    <ul className="dropdown">
                      <li>
                        {/* <Link to="/Testing">Testing Services</Link> */}
                        <Link to="/Testing">
                          Web Application Penetration testing
                        </Link>
                      </li>
                      <li>
                        {/* <Link to="/Assessment">Assessment Services</Link> */}
                        <Link to="/Assessment">Vulnerability Assessment</Link>
                      </li>
                      <li>
                        {/* <Link to="/Security">Security Services</Link> */}
                        <Link to="/Security">Cloud Security</Link>
                      </li>
                      <li>
                        {/* <Link to="/SOC">SOC services</Link> */}
                        <Link to="/SOC">Managed SOC services</Link>
                      </li>
                      <li>
                        {/* <Link to="/Team">Red Team / Blue Team Services</Link> */}
                        <Link to="/Team">Red Team / Blue Team</Link>
                      </li>
                      <li>
                        {/* <Link to="/Development">Development Services</Link> */}
                        <Link to="/Development">
                          Custom Software Development
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </li>
                {/* <a href="https://secoraa.com/web_scanner.php">Web Scanner</a> */}
              {/* <li>
                <a onClick={handleLinkClick}>
                  Web Scanner
                </a>
              </li> */}
              {/* <li>
                <a href="https://secoraa.com/wordpress.php">
                  Wordpress Scanner
                </a>
              </li> */}
              <li>
                <div className="contactUs Colored_text ">
                  <a className="Colored_text" onClick={onContactClick}>
                    Contact
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
